<template>
  <div>
    <el-row>
      <div style="display: inline-block; width: 100%; height: 100%;">
        <div class="child-top" style="padding-top: 0;">
          <div class="input-from">
            <el-select
              class="last-child"
              clearable
              placeholder="请选择训练部位"
              v-model="positionUuid"
              @change="getData(1)"
            >
              <el-option v-for="(item, index) in positionList" :label="item.name" :value="item.uuid" :key="index"></el-option>
            </el-select>
            <el-select
              class="last-child"
              clearable
              placeholder="请选择运动素质"
              v-model="sportQualityUuid"
              @change="getData(1)"
            >
              <el-option v-for="(item, index) in sportQualityList" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>

            <el-input
              class="left-child-input left-child-input-content"
              clearable
              placeholder="请输入训练动作名称"
              style="width: 200px; float: left;"
              suffix-icon="iconfont iconsousuo"
              v-model="keyword"
            ></el-input>
            <el-button class="searchBtn" round @click="getData()">搜索</el-button>
          </div>

          <div class="operation-button">
            <el-button class="new_btn" operation-button round @click="postAdd()"
            :disabled="$store.getters.permissionsStr('新增')" 
            >
              <span>新增</span>
            </el-button>
            <el-button class="del_btn"  round @click="someDels()"
            :disabled="$store.getters.permissionsStr('删除') || !sels.length" 
            >
              <span>删除</span>
            </el-button>
          </div>
        </div>

        <div class="content-flex-pages" style="background: #fff;">
          <!-- :data="!$store.getters.permissionsStr('列表') ? tableData : []" -->

          <el-table
            :empty-text="tableData.length ? '' : '暂无数据'"
            :data="!$store.getters.permissionsStr('列表') ? tableData : []"
            class="content-test-table"
            style="width: 100%;"
            v-loading="pictLoading"
            @selection-change="selsChange"
          >
            <el-table-column type="selection" width="45" :selectable="checkbox"></el-table-column>
            <el-table-column label="序号" type="index" width="45">
              <template slot-scope="scope">{{ (page - 1) * limit + scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column label="训练动作名称" prop="name" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属训练部位" prop="position_name" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属运动素质" prop="sport_quality_cn" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="demo" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip width="80">
              <template slot-scope="scope">
                <el-switch
                  v-show="scope.row.uuid"
                  active-color="#0055E9"
                  :active-value="1"
                  :inactive-value="0"
                  :width="35"
                  @change="ChangeState(scope.row)"
                  inactive-color="#ccc"
                  v-model="scope.row.status"
                  :disabled="$store.getters.permissionsStr('启/停用') || !scope.row.edit_flag"
                ></el-switch>
                  <!-- :disabled="$store.getters.permissionsStr('启/停用')" -->
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  :class="!scope.row.edit_flag || !scope.row.can_op ? 'disabledColor right20' : 'blueColor right20'"
                  :disabled="$store.getters.permissionsStr('编辑') || !scope.row.edit_flag || !scope.row.can_op"
                  size="medium"
                  type="text"
                  @click="postEdit(scope.row)"
                  v-show="scope.row.uuid"
                >编辑</el-button>
                  <!-- :disabled="!scope.row.edit_flag || !scope.row.can_op" -->
                <el-button
                  @click="delTableData(scope.row)"
                  :disabled="$store.getters.permissionsStr('删除') || !scope.row.edit_flag || !scope.row.can_op"
                  :class="!scope.row.edit_flag || !scope.row.can_op ? 'disabledColor right20' : 'redColor right20'"
                  size="medium"
                  type="text"
                  v-show="scope.row.uuid"
                >删除</el-button>
                  <!-- v-show="!$store.getters.permissionsStr('删除')" -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="page"
            :page-size="limit"
            :total="total"
            @current-change="handleCurrentChange"
            class="table-pagination"
            layout="total, prev, pager, next , jumper"
          ></el-pagination>
        </div>
      </div>
    </el-row>
    <el-row>
      <!--    新增对话框-->
      <el-dialog
        :close-on-click-modal="false"
        :title="addMenuTitle"
        v-loading="tableLoading"
        :visible.sync="dialogVisible"
        center
        top="10vh"
        width="700px"
        :show-close="false"
      >
        <!--      新增详情-->
        <el-form
          label-position="left"
          label-width="100px"
          ref="ruleForm"
          style="margin-bottom:20px;"
          :inline="true"
        >
          <el-form-item label="训练动作名称" style="margin-bottom: 20px;" required>
            <el-input placeholder="请输入动作名称" v-model="formAdd.name"></el-input>
          </el-form-item>
          <el-form-item label="所属训练部位" style="margin-bottom: 20px;" required>
            <el-select
              clearable
              placeholder="请选择训练部位"
              v-model="formAdd.position_uuid"
            >
              <el-option v-for="(item, index) in positionList" :label="item.name" :value="item.uuid" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属运动素质" style="margin-bottom: 20px;" required>
            <el-select
              clearable
              placeholder="请选择运动素质"
              v-model="formAdd.sport_quality"
            >
              <el-option v-for="(item, index) in sportQualityList" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="指标属性" required style="width: 100%;">
              <el-radio v-model="formAdd.edit_flag" :label="1">可编辑</el-radio>
              <el-radio v-model="formAdd.edit_flag" :label="0">只读</el-radio>
            </el-form-item>
          <!--                新增编辑备注-->
          <el-form-item label="备注">
            <el-input
              :rows="4"
              :span="24"
              placeholder="请输入内容"
              v-model="formAdd.demo"
              type="textarea"
              style="display:block; width:450px;"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="clearfix"></div>
        <div class="form-bottom-button clearfix">
          <!-- 编辑的 保存-->
          <el-button type="primary" v-no-more-click class="save_btn" round @click="submitAdd()">保 存</el-button>

          <el-button class="cancel_btn" round @click="resetForm()">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictLoading: false,
      tableLoading: false,
      dialogVisible: false,
      addMenuTitle: "",
      sels: [], // 列表选中列
      total: 0, // 总数
      page: 1, // 当前分页
      limit: 10, // 每页条数
      tableData: [{uuid:0}], // 表格数据
      keyword: '',
      formAdd: {
        name: "",
        position_uuid: "",
        sport_quality: "",
        edit_flag: 1,
        demo: ""
      },
      handleType: 1,
      positionUuid: '',
      positionList: [],
      sportQualityUuid: '',
      sportQualityList: []
    };
  },
  mounted() {
    this.$axios.post('p/position/all').then(res => {
      this.positionList = res.data.data
    });
    this.$axios.post('p/action/sportQuality').then(res => {
      this.sportQualityList = res.data.data
    });
    this.getData(1);
  },
  methods: {
    checkbox(row, rowIndex) {
      if(!row.edit_flag) {
        return false
      } else {
        return true
      }
    },
    resetForm() {
      this.formAdd = {
        name: "",
        position_uuid: "",
        sport_quality: "",
        edit_flag: 1,
        demo: ""
      }
      this.dialogVisible = false;
    },
    selsChange(sels) {
      this.sels = sels;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    //列表
    getData(run_type) {
      if (run_type) {
        this.page = run_type;
      }
      this.pictLoading = true;
      this.$axios.post('p/action/lists', this.$qs({
        page: this.page,
        page_size: this.limit,
        keyword: this.keyword,
        position_uuid: this.positionUuid,
        sport_quality: this.sportQualityUuid
      })).then(res => {
        this.pictLoading = false;
        this.tableData = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },
    submitAdd() {
      let params = this.formAdd;
      if (params.name == "") {
        this.$message({
          message: "训练动作名称不能为空",
          type: "error"
        });
        return;
      }
      if (params.name.length > 40) {
        this.$message({
          message: "训练动作名称最大长度40",
          type: "error"
        });
        return;
      }
      if (params.position_uuid == '') {
        this.$message({
          message: "请选择所属训练部位",
          type: "error"
        });
        return;
      }
      if (params.sport_quality == '') {
        this.$message({
          message: "请选择所属运动素质",
          type: "error"
        });
        return;
      }
      if (params.demo.length > 80) {
        this.$message({
          message: "备注最大长度为80",
          type: "error",
          duration: 4000
        });
        return;
      }
      let url = ''
      if (this.handleType == 1) {
        url = "/p/action/add";
      } else {
        url = "/p/action/update";
      }
      this.$axios.post(url, this.$qs(params)).then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: res.data.message,
            type: "success"
          });
          this.getData(1);
          this.resetForm()
        } else {
          this.$message({
            message: res.data.message,
            type: "error"
          });
        }
      }).catch(error => {
        this.$message({
          message: error,
          type: "error"
        });
      });
    },
    delTableData(data) {
      //删除数据
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
        cancelButtonClass: "",
        center: true
      }).then(() => {
        this.$axios.post('/p/action/del', this.$qs({
          uuids: data.uuid
        })).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.message,
              type: "success"
            });
            this.getData();
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
          }
        });
      });
    },
    //新增表单弹窗
    postAdd() {
      this.handleType = 1
      this.dialogVisible = true
      this.addMenuTitle = "新增训练动作"
      this.$axios.post('p/position/all').then(res => {
        this.positionList = res.data.data
      })
    },
    postEdit(data) {
      this.tableLoading = true
      this.handleType = 2
      this.dialogVisible = true
      this.addMenuTitle = "编辑训练动作"
      this.$axios.post('p/position/all', this.$qs({
        uuid: data.uuid,
        type: 'action'
      })).then(res => {
        this.positionList = res.data.data
      })
      this.formAdd = {
        name: data.name,
        position_uuid: data.position_uuid,
        sport_quality: data.sport_quality,
        edit_flag: data.edit_flag,
        demo: data.demo,
        uuid: data.uuid
      }
      setTimeout(() => {
        this.tableLoading = false
      }, 300)
    },
    ChangeState(data) {
      this.$axios.post('/p/action/able', this.$qs({
        uuid: data.uuid
      })).then(res => {
        if (res.data.code == 0) {
          this.$message({
            // message:response.data.message,
            message: res.data.message,
            type: "success"
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
        this.getData();
      });
    },
    someDels() {
      let that = this;
      //删除数据
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
        cancelButtonClass: "",
        center: true
      }).then(() => {
        let params = [];
        let url = "/p/action/del";
        if (that.sels.length == 0) {
          that.$message({
            message: "请选择要删除的数据",
            type: "error",
            duration: 4000
          });
          return;
        }
        for (let i = 0; i < this.sels.length; i++) {
          params[i] = this.sels[i].uuid;
        }
        let ids = params.join(",");
        this.$axios.post(url, this.$qs({
          uuids: ids
        })).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.message,
              type: "success"
            });
            this.getData();
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
          }
        }).catch(error => {
          this.$message({
            message: error,
            type: "error"
          });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.child-top {
  .input-from {
    float: left;
    min-width: 750px;
    margin-bottom: 0;

    .searchBtn{
      margin-top:20px;
    }

    & > .el-select {
      width: 150px;
      float: left;
      margin-top: 20px;

      .el-input__inner {
        border-radius: 0;
        border-right: transparent;
      }

      .el-input__inner:focus {
        border-right: 1px solid #409eff;
      }
    }

    .el-select,
    .el-input {
      margin-top: 20px;
    }

    .el-select:nth-child(4) {
      .el-input__inner:focus {
        border-right: 0.052083rem solid #409eff;
      }
    }

    .el-select:nth-child(5) {
      .el-input__inner {
        border-right: 0;
      }

      .el-input__inner:focus {
        border-right: 0.052083rem solid #409eff;
      }
    }

    .el-select:nth-child(6) {
      width: 120px;
      margin-left: 20px;

      .el-input__inner {
        border-radius: 0px 4px 4px 0;
      }
    }

    .el-select:nth-child(1) {
      .el-input__inner {
        border-radius: 4px 0 0 4px;
      }
    }

    .el-input.el-input--suffix.left-child-input {
      float: left;
      width: 200px;

      .el-input__inner {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .operation-button {
    // float: left;
    margin-bottom: 10px;
  }
}
.ModifyDoalog {
  margin-top: -20px;
  padding: 0 0 0 20px;
  & > p {
    font-size: 20px;
    color: #3b3b3b;
    text-align: center;
    margin-bottom: 20px;
  }
  .el-form {
    padding-right: 30px;
    .form_inRact {
      display: flex;
      margin-bottom: 20px;
      .el-form-item:first-child {
        margin-right: 5%;
      }
    }
  }
}
.table-pagination {
  text-align: right;
  margin-top: 10px;
  /deep/ span {
    float: left;
  }
}
.newTable {
  height: 400px;
  overflow: auto;
  .el-table {
    width: 40%;
  }
}

/deep/ input::-webkit-input-placeholder {
  color: #999;
}
/deep/ input::-moz-input-placeholder {
  color: #999;
}
/deep/ input::-ms-input-placeholder {
  color: #999;
}

.iconshanchu-moren {
  &:hover {
    color: #f00;
    cursor: pointer;
  }
}

.el-pagination {
  background: #fff;
  padding: 10px;
  margin-top: 0;
}
.child-top .operation-button .del_btn:hover {
  background: #eb1e44;
  color: #ffffff;
}
</style>


